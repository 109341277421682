var $ = require("jquery")
import ScrollReveal from 'scrollreveal'
window.$ = window.jQuery = $ // notice the definition of global variables here

console.log("Alife Foods @ 2021")
$(() => {
    $(".faq").on("click", function () {
        $(this).toggleClass("faq-toggle")
    })
    $("#scroll-faq").on("click", function () {
        document.querySelector('#faq').scrollIntoView({
            behavior: 'smooth'
        });
    })
    window.sr = new ScrollReveal();
    ScrollReveal().reveal('.container');
    ScrollReveal().reveal('.profile-pic', {reset: true});
    ScrollReveal().reveal('.illu-container', {reset: true});
    ScrollReveal().reveal('.faq');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params["newsletter"]=="confirmation"){
        jQuery("#mc_embed_signup").html("<p style='color:green;'>Thank you for joining our newsletter! Please confirm your subscription.</p>")
        location.href = "#join";
    }
    if(params["newsletter"]=="signup"){
        location.href = "#join";
    }
});



